import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";
import axios from "axios";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperPager from "../../controls/swiperPager";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination, Keyboard } from "swiper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LearningElement,LearningJourneyElement } from "./LearningStyles";

const Learning = () => {
  const [loading, setLoading] = useState(true);
  const [learningData, setLearningData] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [activeTab, setActiveTab] = useState("recommended");
  const [courses, setCourses] = useState(null);
  const [currentSwiper, setCurrentSwiper] = useState(null);
  const [journeySwiper, setJourneySwiper] = useState(null);
  const { cmpData, environment, cuesURL, cmanURL, degreedURL } = useAuth();
  const icnCourse = require("../../assets/images/icn_courses@2x.png");
  const icnJourney = require("../../assets/images/icn_journeys@2x.png");
  function handleGAEvent(eventTitle, value) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          value,
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }
  function truncateStringWithPTag(inputString, maxLength) {
    if (inputString === undefined || inputString === null)
      return inputString;
    // Regular expression to match <p></p> tags and their content
    const regex = /(<([^>]+)>)/gi;

    // Replace all <p></p> tags with an empty string
    const stringWithoutPTags = inputString.replace(regex, '');

    // If there are no <p></p> tags, return the original input string
    if (!stringWithoutPTags.trim()) {
      return inputString;
    }

    // Truncate the resulting string to the specified maxLength
    if (stringWithoutPTags.length > maxLength) {
      const lastSpaceIndex = stringWithoutPTags.lastIndexOf(' ', maxLength);
      if (lastSpaceIndex !== -1) {
        return stringWithoutPTags.substring(0, lastSpaceIndex) + '...';
      }
      return stringWithoutPTags.substring(0, maxLength) + '...';
    }

    return stringWithoutPTags;
  }


  useEffect(() => {
    let novoedApiUrl;

    if (environment == "local") {
      let data = require( "./learningTestData");
      setRecommendations(data.recommendations)
      setLearningData(data.learning);

      setLoading(false);
    } else {
      novoedApiUrl = cuesURL + "/cmp/novoed"
      var persona = cmpData.account_info?.hasNovoEdCourseData !== true?`?persona=${cmpData.account_info.acgiRole}`:"";
      var recommendationUrl = `${cuesURL}/course-recommendations/${cmpData.account_info?.memberId}${persona}`
      axios
        .get(recommendationUrl)
        .then(function (response) {
          console.log('Recommendations')
          console.log(response.data)
          console.log('recommendations end')
          setRecommendations(response.data);
          axios
            .get(novoedApiUrl)
            .then(function (response1) {
              setLearningData(response1.data);
              setLoading(false);
            })
            .catch(function (error1) {
              setLoading(false);
            });
        })
        .catch(function (error) {
          setLoading(false);
        });

    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      switch (activeTab) {
        case "recommended":
          console.log('recommended')
          const rawData = recommendations.recommendations;
          //setRecommendations(rawData)
          //console.log(rawData)
          break;
        case "enrolled":
          let enrolledData = learningData.learning.enrolled;

          if (Array.isArray(enrolledData)) {
            const journeys = enrolledData.filter(item => item.type === "Journey");
            const otherTypes = enrolledData.filter(item => item.type !== "Journey");

            const sortedJourneys = journeys.sort((a, b) => {
              const dateA = new Date(a.order_date);
              const dateB = new Date(b.order_date);
              return dateB - dateA;
            });

            const sortedOtherTypes = otherTypes.sort((a, b) => {
              const getStatus = (item) => {
                if (item.completion_progress === "in_progress" && item.last_activity !== null) {
                  return "in_progress";
                } else if (item.last_activity === null) {
                  return "enrolled";
                } else {
                  return item.completion_progress;
                }
              };
              const statusA = getStatus(a);
              const statusB = getStatus(b);

              // First, compare the statuses
              if (statusA === "in_progress" && statusB === "enrolled") {
                return -1; // "in_progress" comes before "enrolled"
              } else if (statusA === "enrolled" && statusB === "in_progress") {
                return 1; // "enrolled" comes after "in_progress"
              } else {
                // If the statuses are the same or neither is "in_progress," compare by date in descending order
                const dateA = new Date(a.order_date);
                const dateB = new Date(b.order_date);
                return dateB - dateA;
              }
            });

            const enrolledSortedData = [...sortedJourneys, ...sortedOtherTypes];
            setCourses(enrolledSortedData);
          } else {
            setCourses([]);
          }

          break;
        case "completed":
          let completedData = learningData.learning.completed;

          if (Array.isArray(completedData)) {
            // Sort the enrolledData array by both 'order_date' and 'title' properties
            const completedSortedData = [...completedData].sort((a, b) => {
              // Compare by 'order_date' first
              const dateA = new Date(a.completed_at);
              const dateB = new Date(b.completed_at);
              if (dateA > dateB) {
                return -1;
              } else if (dateA < dateB) {
                return 1;
              } else {
                // If 'order_date' is the same, compare by 'title'
                const titleA = a.name.toLowerCase();
                const titleB = b.name.toLowerCase();
                return titleA.localeCompare(titleB);
              }
            });
            console.log(`completed count:${completedData.length}`)
            setCourses(completedSortedData);
          } else {
            setCourses([]);
          }

          break;
        default:
          console.log("I don't know what courses to show...");
      }
    }
  }, [loading, activeTab]);
  const getLearningJourneys = () => {
    const rawData = learningData.learning.learning_journeys;
    console.log(rawData)
    if (Array.isArray(rawData)) {
      // Sort the rawData array by the 'title' property
      const sortedData = [...rawData].sort((a, b) => {
        const titleA = a.name.toLowerCase();
        const titleB = b.name.toLowerCase();
        return titleA.localeCompare(titleB);
      });
      return sortedData;
    } else {
      return [];
    }
  }
  const handleRecommendationClick = (item) => {
    console.log(`recommendation click:${item?.course_id}`)
    var url = 'https://dj55151rvi.execute-api.us-east-2.amazonaws.com/Prod/feedback';
    var body = {
      "userId": recommendations.user_id,
      "itemId": item.item_id,
      "eventType": "clicked",
      "recommendationId": item.recommendation_id,
      "campaign": "mcltoppicks",
      "sessionId": window.analytics.user().anonymousId()
    };
    axios
      .post(url, body, { withCredentials: false })
      .then(function (response) {
        console.log(response)
        //setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      });
    console.log(body)
  }
  const [step1open, setStep1open] = useState(localStorage.getItem('hasViewedOverlay') !== 'true');
  const [step2open, setStep2open] = useState(false);
  const [step3open, setStep3open] = useState(false);

  const handleToggle = () => {
    setStep1open((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setStep1open(false);
  };

  return (
    <div id="cueslearning">
      {loading && (
        <LearningElement>
          <div className="cmp-learning-container">
            {/* Header */}
            <div className="cmp-learning-header">
              <div className="cmp-learning-header-content">
                <h2>Learning</h2>
              </div>
            </div>

            <div className="loading-container">
              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={3} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>

              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={3} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>

              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={3} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LearningElement>
      )}

      {!loading && learningData && (
        <>

          <LearningElement>
            <div className="cmp-learning-container">

              {/* Header */}
              <div className="cmp-learning-header">
                <div className="cmp-learning-header-content">
                  <h2>myCUES Learning</h2>
                </div>

                <ul className="cmp-learning-tabs">
                  <li>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#0c66a5"
                          },
                        },
                      }}
                      PopperProps={{
                        popperOptions: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10], // adjust the offset as needed
                              },
                            },
                          ],
                        },
                      }}
                     
                      open={step1open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="top"
                      style={{ backgroundColor: '#0c66a5!important' }}
                      className="custom-tooltip"
                      arrow
                      backgroundColor="#0c66a5"
                      title={
                        <ClickAwayListener  >
                          <div style={{ width: 300, height: "auto", padding: '20px', marginBottom:'15px' }}>
                            <h2 style={{fontSize:'18px', marginBottom:'5px', fontWeight:"600"}}>Welcome to myCUES Learning!</h2>
                            <p style={{marginBottom:"15px",fontSize:"14px"}}>Here you'll find recommended courses based on your interests and engagement.</p>
                            <a onClick={() => { setStep1open(false);setStep2open(true);setActiveTab("enrolled"); }} 
                              style={{
                                color:"red!important",
                                borderRadius:"30px",
                                border:"1.5px solid #fff",
                                padding: "4px 16px",
                                fontSize:"14px",
                                
                                float:"right"
                              }}
                              onMouseEnter={e => {e.target.style.color = '#0c66a5';e.target.style.backgroundColor="#fff"}} // Change color on hover
                              onMouseLeave={e => {e.target.style.color = '#fff';e.target.style.backgroundColor="#0c66a5"}} // Revert color on mouse out
                            >Got it</a>
                          </div>
                        </ClickAwayListener>
                      }
                    >
                      <button
                        className={activeTab == "recommended" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("recommended");
                        }}
                      >
                        Courses Recommended for {cmpData.account_info.firstName}
                      </button>
                    </Tooltip>

                  </li>
                  <li>
                  <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#0c66a5"
                          },
                        },
                      }}
                      PopperProps={{
                        popperOptions: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10], // adjust the offset as needed
                              },
                            },
                          ],
                        },
                      }}
                     
                      open={step2open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="top"
                      style={{ backgroundColor: '#0c66a5!important' }}
                      className="custom-tooltip"
                      arrow
                      backgroundColor="#0c66a5"
                      title={
                        <ClickAwayListener  >
                          <div style={{ width: 300, height: "auto", padding: '20px', marginBottom:'15px' }}>
                            <h2 style={{fontSize:'18px', marginBottom:'5px', fontWeight:"600"}}>Enrolled</h2>
                            <p style={{marginBottom:"15px",fontSize:"14px"}}>Here you'll find all of the courses and learning journeys you're currently enrolled in.</p>
                            <a onClick={() => { setStep2open(false);setStep3open(true); setActiveTab("completed");}} 
                              style={{
                                color:"red!important",
                                borderRadius:"30px",
                                border:"1.5px solid #fff",
                                padding: "4px 16px",
                                fontSize:"14px",
                                
                                float:"right"
                              }}
                              onMouseEnter={e => {e.target.style.color = '#0c66a5';e.target.style.backgroundColor="#fff"}} // Change color on hover
                              onMouseLeave={e => {e.target.style.color = '#fff';e.target.style.backgroundColor="#0c66a5"}} // Revert color on mouse out
                            >Got it</a>
                          </div>
                        </ClickAwayListener>
                      }
                    >
                      <button
                        className={activeTab == "enrolled" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("enrolled");
                        }}
                      >
                        Enrolled
                      </button>
                    </Tooltip>

                  </li>
                  <li>
                  <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#0c66a5"
                          },
                        },
                      }}
                      PopperProps={{
                        popperOptions: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10], // adjust the offset as needed
                              },
                            },
                          ],
                        },
                      }}
                     
                      open={step3open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="top"
                      style={{ backgroundColor: '#0c66a5!important' }}
                      className="custom-tooltip"
                      arrow
                      backgroundColor="#0c66a5"
                      title={
                        <ClickAwayListener  >
                          <div style={{ width: 300, height: "auto", padding: '20px', marginBottom:'15px' }}>
                            <h2 style={{fontSize:'18px', marginBottom:'5px', fontWeight:"600"}}>Completed</h2>
                            <p style={{marginBottom:"15px",fontSize:"14px"}}>Here you may view completed courses and download certificates of completion.</p>
                            <a onClick={() => { setStep3open(false);localStorage.setItem('hasViewedOverlay', 'true');setActiveTab("recommended"); }} 
                              style={{
                                color:"red!important",
                                borderRadius:"30px",
                                border:"1.5px solid #fff",
                                padding: "4px 16px",
                                fontSize:"14px",
                                
                                float:"right"
                              }}
                              onMouseEnter={e => {e.target.style.color = '#0c66a5';e.target.style.backgroundColor="#fff"}} // Change color on hover
                              onMouseLeave={e => {e.target.style.color = '#fff';e.target.style.backgroundColor="#0c66a5"}} // Revert color on mouse out
                            >Got it</a>
                          </div>
                        </ClickAwayListener>
                      }
                    >
                     <button
                      className={activeTab == "completed" ? "active" : ""}
                      onClick={() => {
                        setActiveTab("completed");
                      }}
                    >
                      Completed
                    </button>
                    </Tooltip>
                    
                  </li>
                </ul>

                <a
                  href={"https://cues.novoed.com/#!/users/sign_in"}
                  target="_blank"
                  className="cmp-clp-visit-button"
                  onClick={() => {
                    handleGAEvent("learning_visitportal");
                  }}
                >
                  Visit CUES Learning Portal
                </a>
              </div>

              {activeTab == "recommended" && (
                <>

                  {/* Desktop/Tablet - Slider */}
                  <div className="cmp-learning-assignments cmp-learning-assignments-with-slider">
                    {recommendations && recommendations.recommendations.length > 0 ? (
                      <>
                        <Swiper
                          simulateTouch={false}
                          slidesPerView={1} // Default slidesPerView
                          slidesPerGroup={1} // Default slidesPerGroup
                          freeMode={false}
                          grid={{
                            rows: 2,
                          }}
                          spaceBetween={30}
                          onSwiper={(swiper) => setCurrentSwiper(swiper)}

                          // pagination={{
                          //   clickable: true,
                          // }}
                          pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                              return ``;
                            }
                          }}
                          modules={[Grid, Pagination]}
                          className="mySwiper"
                          breakpoints={{
                            480: {
                              slidesPerView: 1,
                              slidesPerGroup: 1,
                              // grid: {
                              //   rows: 2,
                              // },
                            },
                            769: {
                              slidesPerView: 3,
                              slidesPerGroup: 3,
                              // grid: {
                              //   rows: 2,
                              // },
                            },
                          }}
                        >
                          <>
                            {Object.values(recommendations.recommendations).slice(0,18).map((course) => (
                              <SwiperSlide key={course.course_id}>
                                <div className="cmp-learning-card"
                                  data-learning-type={`${course.course_record.type}`}
                                  data-learning-id={`${course.course_id}`}
                                  data-catalog-id={`${course.course_record.catalog_id}`}
                                  data-topics={`${course.topics}`}
                                  data-personas={`${course.personas}`}
                                  data-learning-journeys={`${course.learning_journeys}`}
                                  data-novoed-status="none"
                                  data-view-roles={`${course.tiers}`}
                                  data-course-series={`${course.series}`}
                                  data-recommended-course="true"
                                >

                                  <div className="cmp-learning-card-header">
                                    <span className="cmp-learning-type"><img src={icnCourse} />COURSE</span>
                                    {course.course_record?.name && (
                                      <p className="cmp-learning-card-title">
                                        {truncateStringWithPTag(course.course_record?.name, 60)}
                                      </p>
                                    )}
                                    {/* {course.class_summary &&
                                  ( */}

                                    <div className="cmp-learning-card-course-length">
                                      {truncateStringWithPTag(course.course_record?.class_summary, 100)}
                                    </div>
                                  </div>

                                  <div className="cmp-learning-card-footer">
                                    {course.course_record?.course_url && (
                                      <a
                                        href={course.course_record?.course_url}
                                        target="_blank"
                                        className="cmp-learning-card-link resume"
                                        onClick={() => {
                                          handleRecommendationClick(course);
                                          handleGAEvent(
                                            "learning_assigned",
                                            course.name
                                          );
                                        }}
                                      >
                                        Enroll
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </>
                        </Swiper>
                        <SwiperPager swiper={currentSwiper} itemCount={recommendations.recommendations.slice(0,18).length} pageRangeCount={8}
                          itemsPerPage={9} currentPage={0}></SwiperPager>


                      </>
                    ) : (
                      <div className="cmp-learning-card recommendations empty">
                        <div className="cmp-learning-card-header">
                          <span className="cmp-learning-type"><img src={icnCourse} />COURSE RECOMMENDATIONS</span>
                          <p className="cmp-learning-card-title">
                            CUES Learning Portal
                          </p>
                          <div className="cmp-learning-card-course-length">
                          Head to CUES Learning Portal to view curated resources to enhance your skills and knowledge.
                          </div>
                          {/* )} */}

                        </div>

                        <div className="cmp-learning-card-footer">

                          <a
                            href="https://cues.novoed.com/#!/users/sign_in"
                            target="_blank"
                            className="cmp-learning-card-link resume"
                          // onClick={() => {
                          //   handleGAEvent(
                          //     "learning_assigned",
                          //     course.name
                          //   );
                          // }}
                          >
                            Explore Now
                          </a>

                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {activeTab == "enrolled" && (
                <>

                  {/* Desktop/Tablet - Slider */}
                  <div className="cmp-learning-assignments cmp-learning-assignments-with-slider">
                    {courses && courses.length > 0 ? (
                      <>
                        <Swiper
                          simulateTouch={false}
                          slidesPerView={1}
                          freeMode={false}
                          grid={{
                            rows: 2,
                          }}
                          spaceBetween={30}
                          pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                              return ``;
                            }
                          }}
                          onSwiper={(swiper) => setCurrentSwiper(swiper)}
                          modules={[Grid, Pagination]}
                          className="mySwiper"
                          breakpoints={{
                            480: {
                              slidesPerView: 1,
                              slidesPerGroup: 1,
                              // grid: {
                              //   rows: 2,
                              // },
                            },
                            769: {
                              slidesPerView: 3,
                              slidesPerGroup: 3,
                              // grid: {
                              //   rows: 2,
                              // },
                            },
                          }}
                        >
                          <>
                            {Object.values(courses).map((course) => (
                              <SwiperSlide key={course.course_id}>
                                <div className="cmp-learning-card"
                                  data-learning-type={`${course.type}`}
                                  data-learning-id={`${course.course_id}`}
                                  data-catalog-id={`${course.catalog_id}`}
                                  data-topics={course.drupal_values && course.drupal_values.topics ? `${course.drupal_values.topics}` : ''}
                                  data-personas={course.drupal_values && course.drupal_values.personas ? `${course.drupal_values.personas}` : ''}
                                  data-learning-journeys={course.drupal_values && course.drupal_values.learning_journeys ? `${course.drupal_values.learning_journeys}` : ''}
                                  data-novoed-status={`${course.completion_progress}`}
                                  data-view-roles={course.drupal_values && course.drupal_values.tier ? `${course.drupal_values.tier}` : ''}
                                  data-course-series={course.drupal_values && course.drupal_values.series ? `${course.drupal_values.series}` : ''}
                                  data-recommended-course="false"
                                >
                                  <div className="cmp-learning-card-header">
                                    {course.type && course.type === 'Course' && (
                                      <span className="cmp-learning-type"><img src={icnCourse} />Course</span>
                                    )}

                                    {course.type && course.type === 'Journey' && (
                                      <span className="cmp-learning-type"><img src={icnJourney} />Learning Journey</span>
                                    )}

                                    {course.name && (
                                      <p className="cmp-learning-card-title">
                                        {truncateStringWithPTag(course.name, 60)}
                                      </p>
                                    )}
                                    {/* {course.class_summary &&
                                  ( */}

                                    <div className="cmp-learning-card-course-length">
                                      {truncateStringWithPTag(course.class_summary, 100)}
                                    </div>
                                    {/* )} */}

                                  </div>

                                  <div className="cmp-learning-card-footer">
                                    {course.course_url && (
                                      <a
                                        href={course.course_url}
                                        target="_blank"
                                        className="cmp-learning-card-link resume"
                                        onClick={() => {
                                          handleGAEvent(
                                            "learning_assigned",
                                            course.name
                                          );
                                        }}
                                      >
                                        {course.type === "Journey" && `View`}
                                        {course.type === "Course" && (
                                          <>
                                            {course.completion_progress === "in_progress" && course.last_activity ? `Resume` : `Start`}
                                          </>
                                        )}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>

                            ))}
                          </>
                        </Swiper>
                        <SwiperPager swiper={currentSwiper} itemCount={courses.length} pageRangeCount={8}
                          itemsPerPage={9} currentPage={0}></SwiperPager>
                      </>
                    ) : (
                      <div className="cmp-learning-card empty">
                        <div className="cmp-learning-card-header">
                          <div className="cmp-learning-card-title">
                            You're currently not enrolled in any learning. Get started on your own by visiting CUES Learning Portal!
                          </div>
                          {/* )} */}

                        </div>

                        <div className="cmp-learning-card-footer">

                          <a
                            href="https://cues.novoed.com/#!/users/sign_in"
                            target="_blank"
                            className="cmp-learning-card-link resume"
                          // onClick={() => {
                          //   handleGAEvent(
                          //     "learning_assigned",
                          //     course.name
                          //   );
                          // }}
                          >
                            Explore Courses
                          </a>

                        </div>
                      </div>

                    )}
                  </div>
                </>
              )}
              {activeTab == "completed" && (
                <>

                  {/* Desktop/Tablet - Slider */}
                  <div className="cmp-learning-assignments cmp-learning-assignments-with-slider">
                    {courses && courses.length > 0 ? (
                      <>
                        <Swiper
                          simulateTouch={false}
                          slidesPerView={1}
                          freeMode={false}
                          grid={{
                            rows: 2,
                          }}
                          spaceBetween={30}
                          pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                              return ``;
                            }
                          }}
                          modules={[Grid, Pagination]}
                          onSwiper={(swiper) => setCurrentSwiper(swiper)}
                          className="mySwiper"
                          breakpoints={{
                            480: {
                              slidesPerView: 2,
                              slidesPerGroup: 4,
                              // grid: {
                              //   rows: 2,
                              // },
                            },
                            769: {
                              slidesPerView: 3,
                              slidesPerGroup: 3,
                              // grid: {
                              //   rows: 2,
                              // },
                            },
                          }}
                        >
                          <>
                            {Object.values(courses).map((course) => (
                              <SwiperSlide>
                                <div className="cmp-learning-card"
                                  data-learning-type={`${course.type}`}
                                  data-learning-id={`${course.course_id}`}
                                  data-catalog-id={`${course.catalog_id}`}
                                  data-topics={course.drupal_values && course.drupal_values.topics ? `${course.drupal_values.topics}` : ''}
                                  data-personas={course.drupal_values && course.drupal_values.personas ? `${course.drupal_values.personas}` : ''}
                                  data-learning-journeys={course.drupal_values && course.drupal_values.learning_journeys ? `${course.drupal_values.learning_journeys}` : ''}
                                  data-novoed-status={`${course.completion_progress}`}
                                  data-view-roles={course.drupal_values && course.drupal_values.tier ? `${course.drupal_values.tier}` : ''}
                                  data-course-series={course.drupal_values && course.drupal_values.series ? `${course.drupal_values.series}` : ''}
                                  data-recommended-course="false"
                                >
                                  <div className="cmp-learning-card-header">

                                    {course.type && course.type === 'Course' && (
                                      <span className="cmp-learning-type"><img src={icnCourse} />Course</span>
                                    )}

                                    {course.type && course.type === 'Journey' && (
                                      <span className="cmp-learning-type"><img src={icnJourney} />Learning Journey</span>
                                    )}


                                    {course.name && (
                                      <p className="cmp-learning-card-title">
                                        {truncateStringWithPTag(course.name, 60)}
                                      </p>
                                    )}

                                    {course.class_summary &&
                                      (
                                        <div className="cmp-learning-card-course-length">
                                          {truncateStringWithPTag(course.class_summary, 100)}
                                        </div>
                                      )}
                                  </div>

                                  <div className="cmp-learning-card-footer">
                                    {(course.course_url && (new Date(course.close_date) >= new Date() || course.close_date === null)) && (
                                      <a
                                        href={course.course_url}
                                        target="_blank"
                                        className="cmp-learning-card-link"
                                        onClick={() => {
                                          handleGAEvent(
                                            "learning_assigned",
                                            course.name
                                          );
                                        }}
                                      >
                                        View
                                      </a>
                                    )}

                                    {course["due-at"] && (
                                      <p className="cmp-learning-card-due-date">
                                        {`Due: ${new Date(
                                          course["due-at"]
                                        ).toLocaleDateString()}`}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </>
                        </Swiper>
                        <SwiperPager swiper={currentSwiper} itemCount={courses.length} pageRangeCount={8}
                          itemsPerPage={9} currentPage={0}></SwiperPager>
                      </>
                    ) : (

                      <div className="cmp-learning-card empty">
                        <div className="cmp-learning-card-header">

                          <p className="cmp-learning-card-title">
                            Get started on your learning journey in CUES Learning Portal!
                          </p>
                          {/* )} */}

                        </div>

                        <div className="cmp-learning-card-footer">

                          <a
                            href="https://cues.novoed.com/#!/users/sign_in"
                            target="_blank"
                            className="cmp-learning-card-link resume"

                          >
                            Explore Courses
                          </a>

                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </LearningElement>
          <LearningJourneyElement>
            {!loading && learningData && (
              <>
                <div className="cmp-learningjourney-container">
                  {/* Header */}
                  <div className="cmp-learningjourney-header">

                    <div className="cmp-learningjourney-header-content">
                      <h2>Discover Tailored Learning Journeys</h2>
                      <p style={{ color: "#fff" }}>Explore thoughtfully crafted learning journeys designed to enhance your professional development growth.</p>
                    </div>

                  </div>
                  <div className="cmp-learningjourney-feed">
                    {learningData.learning?.learning_journeys && learningData.learning?.learning_journeys.length > 0 ? (
                      <Swiper
                        simulateTouch={true}
                        slidesPerView={"auto"}
                        slidesPerGroup={3}
                        spaceBetween={30}
                        keyboard={{
                          enabled: true,
                        }}
                        pagination={{
                          type: 'none'
                        }}
                        modules={[Pagination, Keyboard]}
                        className="mySwiper">
                        <>
                          {Object.values(getLearningJourneys()).map((course) => (
                            <SwiperSlide>
                              <div className="cmp-learningjourney-card" data-learning-type={`${course.type}`} data-learning-id={`${course.id}`} data-catalog-id={`${course.catalog_id}`} data-novoed-status={`${course.novoEdStatus}`} data-personas={course.drupal_values && course.drupal_values.personas ? `${course.drupal_values.personas}` : ''} data-topics={course.drupal_values && course.drupal_values.topics ? `${course.drupal_values.topics}` : ''} data-view-roles={course.drupal_values && course.drupal_values.tier ? `${course.drupal_values.tier}` : ''}>
                                <div className="cmp-learningjourney-card-header">

                                  {course.type && course.type === 'Course' && (
                                    <span className="cmp-learningjourney-type"><img src={icnCourse} />Course</span>
                                  )}

                                  {course.type && course.type === 'Journey' && (
                                    <span className="cmp-learningjourney-type"><img src={icnJourney} />Learning Journey</span>
                                  )}


                                  {course.name && (
                                    <p className="cmp-learningjourney-card-title">
                                      {truncateStringWithPTag(course.name, 50)}
                                    </p>
                                  )}

                                  {course.class_summary &&
                                    (
                                      <div className="cmp-learningjourney-card-course-length">
                                        {truncateStringWithPTag(course.class_summary, 80)}
                                      </div>
                                    )}
                                </div>

                                <div className="cmp-learningjourney-card-footer">
                                  {(course.course_url && (new Date(course.close_date) >= new Date() || course.close_date === null)) && (
                                    <a
                                      href={course.course_url}
                                      target="_blank"
                                      className="cmp-learningjourney-card-link"
                                      onClick={() => {
                                        handleGAEvent(
                                          "learning_assigned",
                                          course.name
                                        );
                                      }}
                                    >
                                      View
                                    </a>
                                  )}

                                  {course["due-at"] && (
                                    <p className="cmp-learningjourney-card-due-date">
                                      {`Due: ${new Date(
                                        course["due-at"]
                                      ).toLocaleDateString()}`}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </>
                      </Swiper>
                    ) : <></>}
                  </div>
                  {/* Mobile Feed */}
                  <div className="cmp-learningjourney-feed-mobile">
                    {learningData.learning?.learning_journeys && learningData.learning?.learning_journeys.length > 0 ? (
                      <>
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          pagination={{
                            clickable: true,
                            
                          }}
                          modules={[Grid, Pagination]}
                          className="mySwiper"
                          onSwiper={(swiper) => setJourneySwiper(swiper)}
                        >
                          <>
                            {Object.values(getLearningJourneys()).map((course) => (
                              <SwiperSlide>
                                <div className="cmp-learningjourney-card" data-learning-type={`${course.type}`} data-learning-id={`${course.course_id}`} data-catalog-id={`${course.catalog_id}`}>
                                  <div className="cmp-learningjourney-card-header">

                                    {course.type && course.type === 'Course' && (
                                      <span className="cmp-learningjourney-type"><img src={icnCourse} />Course</span>
                                    )}

                                    {course.type && course.type === 'Journey' && (
                                      <span className="cmp-learningjourney-type"><img src={icnJourney} />Learning Journey</span>
                                    )}


                                    {course.name && (
                                      <p className="cmp-learningjourney-card-title">
                                        {truncateStringWithPTag(course.name, 50)}
                                      </p>
                                    )}

                                    {course.class_summary &&
                                      (
                                        <div className="cmp-learningjourney-card-course-length">
                                          {truncateStringWithPTag(course.class_summary, 80)}
                                        </div>
                                      )}
                                  </div>

                                  <div className="cmp-learningjourney-card-footer">
                                    {(course.course_url && (new Date(course.close_date) >= new Date() || course.close_date === null)) && (
                                      <a
                                        href={course.course_url}
                                        target="_blank"
                                        className="cmp-learningjourney-card-link"
                                        onClick={() => {
                                          handleGAEvent(
                                            "learning_assigned",
                                            course.name
                                          );
                                        }}
                                      >
                                        View
                                      </a>
                                    )}

                                    {course["due-at"] && (
                                      <p className="cmp-learningjourney-card-due-date">
                                        {`Due: ${new Date(
                                          course["due-at"]
                                        ).toLocaleDateString()}`}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </>
                        </Swiper>
                       
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                </div>

              </>
            )}
          </LearningJourneyElement>
        </>
      )}
    </div>
  );
};

export default Learning;
