module.exports = {
    learning: {
        "learning": {

          "enrolled": [
            {
              "user_id": 3260310,
              "course_id": 33543,
              "course_role_id": null,
              "last_activity": "2023-11-10T18:12:52.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:08:11Z",
              "course_home_first_visited_at": "2023-11-10T18:08:11.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:12:52Z",
              "self_paced_access_end": "2024-02-09T18:08:11.000Z",
              "catalog_id": "novoed-testing-course4",
              "name": "Testing Course 4",
              "type": "Course",
              "release_date": "2023-08-01T13:00:00Z",
              "close_date": "2024-02-27T20:16:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course4\/home",
              "order_date": "2023-11-10T18:08:11Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33544,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:02:09Z",
              "course_home_first_visited_at": "2023-11-10T18:02:10.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:02:10Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey7",
              "name": "Testing Journey 7",
              "type": "Journey",
              "release_date": "2023-03-01T13:00:00Z",
              "close_date": "2024-12-15T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey7\/home",
              "order_date": "2023-11-10T18:02:09Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33546,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:02:34Z",
              "course_home_first_visited_at": "2023-11-10T18:02:37.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:02:37Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey8",
              "name": "Testing Journey 8",
              "type": "Journey",
              "release_date": "2023-11-01T12:00:00Z",
              "close_date": "2024-01-31T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey8\/home",
              "order_date": "2023-11-10T18:02:34Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33547,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-21T21:01:21Z",
              "course_home_first_visited_at": "2023-11-21T21:01:26.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-21T21:01:26Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey9",
              "name": "Testing Journey 9",
              "type": "Journey",
              "release_date": "2023-11-01T12:00:00Z",
              "close_date": "2024-03-21T18:00:00Z",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EJourney Information\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey9\/home",
              "order_date": "2023-11-21T21:01:21Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33549,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-21T21:02:56Z",
              "course_home_first_visited_at": "2023-11-21T21:02:58.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-21T21:02:58Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey11",
              "name": "Testing Journey 11",
              "type": "Journey",
              "release_date": "2023-01-24T05:00:00Z",
              "close_date": "2024-02-28T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey11\/home",
              "order_date": "2023-11-21T21:02:56Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33551,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-12T16:36:38Z",
              "course_home_first_visited_at": null,
              "completion_progress": "enrolled",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-10-12T16:36:38Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey13",
              "name": "Testing Journey 13",
              "type": "Journey",
              "release_date": "2023-04-01T12:00:00Z",
              "close_date": "2024-04-30T16:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey13\/home",
              "order_date": "2023-10-12T16:36:38Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33555,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-12T16:37:33Z",
              "course_home_first_visited_at": null,
              "completion_progress": "enrolled",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-10-12T16:37:33Z",
              "self_paced_access_end": "2023-10-14T16:37:33.000Z",
              "catalog_id": "novoed-testing-course6",
              "name": "Testing Course 6",
              "type": "Course",
              "release_date": "2023-09-01T12:00:00Z",
              "close_date": "2023-11-30T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course6\/home",
              "order_date": "2023-10-12T16:37:33Z"
            },
            {
              "user_id": 3260310,
              "course_id": 34476,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:01:26Z",
              "course_home_first_visited_at": "2023-11-10T18:01:31.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-10T18:01:31Z",
              "self_paced_access_end": null,
              "catalog_id": "learningjourney16simple",
              "name": "Learning Journey 16 Simple",
              "type": "Journey",
              "release_date": "2023-10-27T05:00:00Z",
              "close_date": null,
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ETest Learning Journey with fewer educational elements\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/learningjourney16simple\/home",
              "order_date": "2023-11-10T18:01:26Z"
            },
            {
              "user_id": 3260310,
              "course_id": 34983,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-28T17:54:06Z",
              "course_home_first_visited_at": "2023-11-28T17:54:07.000Z",
              "completion_progress": "in_progress",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": null,
              "record_update_at": "2023-11-28T17:54:07Z",
              "self_paced_access_end": "2023-11-28T17:54:06.000Z",
              "catalog_id": "clone-simple-course",
              "name": "Another Testing Course",
              "type": "Course",
              "release_date": "2023-11-15T06:00:00Z",
              "close_date": null,
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/clone-simple-course\/home",
              "order_date": "2023-11-28T17:54:06Z"
            }
          ],
          "completed": [
            {
              "user_id": 3260310,
              "course_id": 33533,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-21T21:34:17Z",
              "course_home_first_visited_at": "2023-11-21T21:34:19.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-11-21T21:34:17Z",
              "record_update_at": "2023-11-21T21:34:19Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey1",
              "name": "Testing Journey 1",
              "type": "Journey",
              "release_date": "2023-08-01T12:00:00Z",
              "close_date": "2024-03-27T16:00:00Z",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel tortor id diam faucibus hendrerit at nec arcu. Nam dignissim eleifend ultrices. Sed rhoncus massa sed neque consectetur, et varius arcu laoreet.\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey1\/home",
              "order_date": "2023-11-21T21:34:17Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33534,
              "course_role_id": null,
              "last_activity": "2023-10-25T20:01:01.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T19:56:03Z",
              "course_home_first_visited_at": "2023-10-25T19:56:03.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:01:01Z",
              "record_update_at": "2023-10-25T20:01:01Z",
              "self_paced_access_end": "2023-12-28T19:56:03.000Z",
              "catalog_id": "novoed-testing-course1",
              "name": "Testing Course 1",
              "type": "Course",
              "release_date": "2023-08-01T12:00:00Z",
              "close_date": "2024-01-31T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course1\/home",
              "order_date": "2023-10-25T19:56:03Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33535,
              "course_role_id": null,
              "last_activity": "2023-10-25T20:36:04.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:33:11Z",
              "course_home_first_visited_at": "2023-10-25T20:33:11.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:36:04Z",
              "record_update_at": "2023-10-25T20:36:04Z",
              "self_paced_access_end": "2023-10-25T20:33:11.000Z",
              "catalog_id": "novoed-testing-course2",
              "name": "Testing Course 2",
              "type": "Course",
              "release_date": "2023-08-01T12:00:00Z",
              "close_date": "2023-11-26T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course2\/home",
              "order_date": "2023-10-25T20:33:11Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33536,
              "course_role_id": null,
              "last_activity": "2023-10-25T20:03:45.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:01:16Z",
              "course_home_first_visited_at": "2023-10-25T20:01:17.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:03:45Z",
              "record_update_at": "2023-10-25T20:03:45Z",
              "self_paced_access_end": "2023-10-25T20:01:16.000Z",
              "catalog_id": "novoed-testing-course3",
              "name": "Testing Course 3",
              "type": "Course",
              "release_date": "2023-08-01T13:00:00Z",
              "close_date": "2023-11-27T16:00:00Z",
              "class_summary": "\u003Cp\u003EExample course description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel tortor id diam faucibus hendrerit at nec arcu. Nam dignissim eleifend ultrices. Sed rhoncus massa sed neque consectetur, et varius arcu laoreet.\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course3\/home",
              "order_date": "2023-10-25T20:01:16Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33539,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T19:55:46Z",
              "course_home_first_visited_at": "2023-10-25T19:55:48.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:03:45Z",
              "record_update_at": "2023-10-25T20:03:45Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey4",
              "name": "Testing Journey 4",
              "type": "Journey",
              "release_date": "2023-06-01T12:00:00Z",
              "close_date": "2024-03-28T16:00:00Z",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description...\u003C\/p\u003E",
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey4\/home",
              "order_date": "2023-10-25T19:55:46Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33540,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:15:12Z",
              "course_home_first_visited_at": "2023-10-25T20:15:13.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:15:12Z",
              "record_update_at": "2023-10-25T20:15:13Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey5",
              "name": "Testing Journey 5",
              "type": "Journey",
              "release_date": "2023-05-01T12:00:00Z",
              "close_date": "2024-02-29T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey5\/home",
              "order_date": "2023-10-25T20:15:12Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33541,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-10-25T20:23:08Z",
              "course_home_first_visited_at": "2023-10-25T20:32:07.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-10-25T20:36:04Z",
              "record_update_at": "2023-10-25T20:36:04Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey6",
              "name": "Testing Journey 6",
              "type": "Journey",
              "release_date": "2023-04-01T12:00:00Z",
              "close_date": "2024-12-10T17:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey6\/home",
              "order_date": "2023-10-25T20:23:08Z"
            },
            {
              "user_id": 3260310,
              "course_id": 33552,
              "course_role_id": null,
              "last_activity": null,
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-28T15:37:15Z",
              "course_home_first_visited_at": "2023-11-28T15:37:22.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-11-28T15:37:15Z",
              "record_update_at": "2023-11-28T15:37:22Z",
              "self_paced_access_end": null,
              "catalog_id": "novoed-testing-journey14",
              "name": "Testing Journey 14 for testing",
              "type": "Journey",
              "release_date": "2023-05-01T12:00:00Z",
              "close_date": "2024-05-30T16:00:00Z",
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey14\/home",
              "order_date": "2023-11-28T15:37:15Z"
            },
            {
              "user_id": 3260310,
              "course_id": 34472,
              "course_role_id": null,
              "last_activity": "2023-11-10T18:17:53.000Z",
              "role": "No Role",
              "is_course_admin": false,
              "enrolled": true,
              "enrolled_at": "2023-11-10T18:16:12Z",
              "course_home_first_visited_at": "2023-11-10T18:16:13.000Z",
              "completion_progress": "completed",
              "is_mentor": false,
              "unenrolled_at": null,
              "completed_at": "2023-11-10T18:18:57Z",
              "record_update_at": "2023-11-10T18:18:57Z",
              "self_paced_access_end": "2023-11-10T18:16:12.000Z",
              "catalog_id": "testingcourse8easy",
              "name": "Testing Course 9 Simple Journey",
              "type": "Course",
              "release_date": "2023-10-27T05:00:00Z",
              "close_date": null,
              "class_summary": null,
              "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/testingcourse8easy\/home",
              "order_date": "2023-11-10T18:16:12Z"
            }
          ],
          "learning_journeys": [
            {
              "id": 32481,
              "catalog_id": "lj-cues-hmm",
              "name": "Harvard ManageMentor Learning Journey",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-08-30T14:44:21Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EThis collection provides access to over 40 courses covering essential business topics, offering your credit union an easy way to invest in your management and leadership development. We hope you and your team will take full advantage of this on-demand learning and performance support resource from Harvard Business Publishing, the author of Harvard Business Review. \u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-hmm\/home"
            },
            {
              "id": 33394,
              "catalog_id": "lj-cues-critical-thinking",
              "name": "Critical Thinking \u0026 Decision Making",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-23T18:31:06Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ECritical thinking enhances decision-making, problem-solving, and communication skills by fostering analytical skills, an open mindset, and logical reasoning. In this journey, leaders can overcome cognitive biases, evaluate information effectively, and make informed decisions.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-critical-thinking\/home"
            },
            {
              "id": 33440,
              "catalog_id": "lj-cues-resiliency",
              "name": "Resiliency",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T17:52:42Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EResilience is the human capacity to meet adversity, complicated situations, and trauma head-on to learn and recover successfully. This learning journey will help leaders learn to sustain their energy, cope with disruptive changes, and adapt after setbacks.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-resiliency\/home"
            },
            {
              "id": 33443,
              "catalog_id": "lj-cues-trust",
              "name": "Trust",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:06:14Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EUnderstanding ways to build trust with individuals can support your relationships and drive positive results for leaders. These courses help new and existing leaders build trust in the workplace, keep connections open and genuine, and create an environment of respect for themselves and the individuals they are responsible for.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-trust\/home"
            },
            {
              "id": 33444,
              "catalog_id": "lj-cues-leading-others",
              "name": "Leading Others",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:09:47Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ELeaders motivate individuals or groups to achieve a shared mission or vision. They also inspire people to take steps toward achieving success, resolving conflict, and successfully navigating through change. This collection of courses will help team leaders develop skills to increase efficiency, promote learning, and manage team performance.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-leading-others\/home"
            },
            {
              "id": 33445,
              "catalog_id": "lj-cues-leading-self",
              "name": "Leading Self",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:15:04Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ESelf-leadership is crucial because it provides more motivation and accountability for your actions. Leaders who practice governing themselves tend to achieve their goals, build the respect of their teams and colleagues, and successfully fulfill the responsibilities of their roles. This collection of courses will help leaders productively respond to challenging situations and act consistently to the best of their ability.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-leading-self\/home"
            },
            {
              "id": 33446,
              "catalog_id": "lj-cues-leading-the-business",
              "name": "Leading the Business",
              "release_date": "2023-11-01T04:01:00Z",
              "end_date": null,
              "created_at": "2023-09-25T18:20:54Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EThis collection of courses is designed to support and guide individuals in building organizational alignment and overall organizational development.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-leading-the-business\/home"
            },
            {
              "id": 34965,
              "catalog_id": "lj-cues-dec-2024",
              "name": "CUES Director Education Center - Full Learning Journey 2024",
              "release_date": "2024-01-01T06:00:00Z",
              "end_date": null,
              "created_at": "2023-11-16T18:42:45Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ECUES Director Education Center offers courses and tools to enhance your performance as a board member. This journey covers credit union history, membership reporting, and other fiduciary responsibilities required to make strategic decisions in your credit union and the member\u0027s best interest. Completing all of the courses will grant you a full program certificate.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-dec-2024\/home"
            },
            {
              "id": 34998,
              "catalog_id": "cues-governance-plus",
              "name": "Governance+",
              "release_date": "2023-12-11T06:00:00Z",
              "end_date": null,
              "created_at": "2023-11-17T18:03:27Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EBoard governance refers to principles, methods, and processes required to make sound and ethical decisions in your credit union or service organization\u2019s best interest. This learning journey provides access to additional articles, tools, and other resources to enhance the boards\u2019 ability to guide the organization\u2019s mission and vision, determine policies, and ensure accountability to the members.\u003C\/p\u003E\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EPlease Note: Click Start My Journey, above, to open the courses for you to access. You must be enrolled in this learning journey before you can start any course in it.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/cues-governance-plus\/home"
            },
            {
              "id": 35402,
              "catalog_id": "lj-cues-can-dec-2024",
              "name": "CUES Canadian Director Education Center - Full Learning Journey 2024",
              "release_date": "2024-01-01T06:00:00Z",
              "end_date": null,
              "created_at": "2023-12-08T15:37:15Z",
              "official_release_date": null,
              "registration_close_date": null,
              "close_date": null,
              "is_self_paced": null,
              "type_of_registration_in_words": "Open based on Entitlements",
              "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ECUES Canadian Director Education Center offers courses and tools to enhance your performance as a board member. This journey covers credit union history, membership reporting, and other fiduciary responsibilities required to make strategic decisions in your credit union and the member\u0027s best interest.\u003C\/p\u003E",
              "enrollment_limit_in_days": null,
              "type": "Journey",
              "used_for": 0,
              "primary_course_id": null,
              "is_primary": false,
              "course_url": "https:\/\/cues.novoed.com\/#!\/courses\/lj-cues-can-dec-2024\/home"
            }
          ]
        }
      },
    "recommendations":{
        "user_id": "1078471",
        "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
        "recommendations": [
         {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 1",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills -2 ",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skill - 2",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 3",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 4",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills - 5",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills -5 ",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills - 6",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 6",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills -7 ",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 8 ",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills -9 ",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 10 ",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 11",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 12",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 13",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 14",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 15",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 16",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 17",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 18",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 19",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills - 20",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 21",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 22",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 23",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 24",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 25",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 26",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 27",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 28",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 29",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 30",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 31",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 32",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 33",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 34",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 35",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 36",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 37",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 38",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 39",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 40",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 41",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 42",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 43",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 44",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 45",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 46",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 47",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 48",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 49",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 50",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 51",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 52",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 53",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 54",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 55",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },{
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 56",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
          {
            "course_id": "29892",
            "item_id": "29892",
            "title": "Developing Your Coaching Skills",
            "url": "/node/27491/edit",
            "course_catalog_id": "z0qnb",
            "learning_journeys_arr": [
              "Coaching and Mentoring",
              "Leading Others"
            ],
            "learning_journeys": "Coaching and Mentoring|Leading Others",
            "personas_arr": [
              "Manager"
            ],
            "personas": "Manager",
            "content_classification": "Manager",
            "topics_arr": [
              "Human Resources"
            ],
            "topics": "Human Resources",
            "genres_arr": [
              "Human Resources"
            ],
            "genres": "Human Resources",
            "series": "General",
            "tiers_arr": [
              "tier_1",
              "tier_2"
            ],
            "tiers": "tier_1|tier_2",
            "tiers_access_levels": [
              "Unlimited & Unlimited+ Members",
              "Individual Members"
            ],
            "content_role_arr": [
              "tier_1",
              "tier_2"
            ],
            "content_role": "tier_1|tier_2",
            "status": "active",
            "drupal_id": "27491",
            "content_type": "course",
            "author": null,
            "updated_at": "2024-03-07 11:53:06",
            "creation_timestamp": "2024-03-07 11:51:58",
            "recommendation_id": "RID-a5-4e5b-9c68-498474f066b7-CID-5bc494",
            "course_record": {
              "name": "Developing Your Coaching Skills- 57",
              "type": "Course",
              "catalog_id": "z0qnb",
              "release_date": "2023-11-01T04:01:00Z",
              "close_date": null,
              "class_summary": "\u003Cp\u003ESuccessful managers know the importance of helping their employees improve their performance and develop new skills. Coaching is one of the most effective ways to accelerate employees' professional growth.\u003C/p\u003E\u003Cp\u003E When you coach, your role includes clarifying an employee's opportunity for development, collaboratively developing a plan of action, and providing support and resources to help employees accomplish their goals.\u003Cbr\u003E In this course, you will learn how to:\u003C/p\u003E\u003Cp\u003E• Plan for and conduct a formal coaching session\u003Cbr\u003E • Apply coaching skills such as listening and asking questions\u003Cbr\u003E • Monitor the progress of a coaching process\u003Cbr\u003E • Use coaching skills when opportunities arise\u003C/p\u003E",
              "course_url": "https://cues.novoed.com/#!/courses/z0qnb/home"
            }
          },
    
        ]
      }
}